<template>
  <!-- 发票申请-->
  <div class="mine-order">
    <el-tabs v-model="activeName" @tab-click="handleClick" v-loading="tableDataLoading">

      <el-tab-pane label="已下单" name="已下单">
        <el-table border :header-cell-style="getRowClass" :data="BillData" style="width: 100%">
          <el-table-column align="center" prop="orderSn" label="订单编号" width="280">
          </el-table-column>
          <el-table-column align="center" prop="declareLevel" label="申报等级" width="100">
          </el-table-column>
          <el-table-column align="center" prop="certificateName" label="证书名称" width="160">
          </el-table-column>
          <el-table-column align="center" prop="certificateYear" label="年份" width="80">
          </el-table-column>
          <el-table-column align="center" prop="payAmount" label="价格(元)" width="80">
          </el-table-column>
          <!-- <el-table-column align="center" label="支付方式" width="80">
            <template slot-scope="scope">
              {{ scope.row.payType==0?'微信':'' }}
              {{ scope.row.payType==1?'支付宝':'' }}
            </template>
          </el-table-column> -->
          <el-table-column align="center" prop="status" label="状态" width="100">
          </el-table-column>
          <el-table-column align="center" prop="status" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="placeOrder(scope.row)" type="text" size="small">选择支付方式</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="待付款" name="待付款">
        <el-table border :header-cell-style="getRowClass" :data="BillData" style="width: 100%">
          <el-table-column align="center" prop="orderSn" label="订单编号" width="280">
          </el-table-column>
          <el-table-column align="center" prop="declareLevel" label="申报等级" width="100">
          </el-table-column>
          <el-table-column align="center" prop="certificateName" label="证书名称" width="160">
          </el-table-column>
          <el-table-column align="center" prop="certificateYear" label="年份" width="80">
          </el-table-column>
          <el-table-column align="center" prop="payAmount" label="价格(元)" width="80">
          </el-table-column>
          <el-table-column align="center" label="支付方式" width="80">
            <template slot-scope="scope">
              {{ scope.row.payType == 2 ? '微信' : '' }}
              {{ scope.row.payType == 1 ? '支付宝' : '' }}
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="status" label="状态" width="120">
          </el-table-column> -->
          <el-table-column align="center" prop="status" label="操作" width="120">
            <template slot-scope="scope">
              <el-button v-if="scope.row.payType" @click="confirmPayment(scope.row)" type="text"
                size="small">去支付</el-button>
              <el-button v-if="!scope.row.payType" @click="placeOrder(scope.row)" type="text"
                size="small">选择支付方式</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="已付款" name="已付款">
        <el-table border :header-cell-style="getRowClass" :data="BillData" style="width: 100%">
          <el-table-column align="center" prop="orderSn" label="订单编号" width="280">
          </el-table-column>
          <el-table-column align="center" prop="declareLevel" label="申报等级" width="100">
          </el-table-column>
          <el-table-column align="center" prop="certificateName" label="证书名称" width="160">
          </el-table-column>
          <el-table-column align="center" prop="certificateYear" label="年份" width="80">
          </el-table-column>
          <el-table-column align="center" prop="payAmount" label="价格(元)" width="80">
          </el-table-column>
          <el-table-column align="center" label="支付方式" width="80">
            <template slot-scope="scope">
              {{ scope.row.payType == 2 ? '微信' : '' }}
              {{ scope.row.payType == 1 ? '支付宝' : '' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态" width="120">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="交易完成" name="交易完成">
        <el-table border :header-cell-style="getRowClass" :data="BillData" style="width: 100%">
          <el-table-column align="center" prop="orderSn" label="订单编号" width="280">
          </el-table-column>
          <el-table-column align="center" prop="declareLevel" label="申报等级" width="100">
          </el-table-column>
          <el-table-column align="center" prop="certificateName" label="证书名称" width="160">
          </el-table-column>
          <el-table-column align="center" prop="certificateYear" label="年份" width="80">
          </el-table-column>
          <el-table-column align="center" prop="payAmount" label="价格(元)" width="80">
          </el-table-column>
          <el-table-column align="center" label="支付方式" width="80">
            <template slot-scope="scope">
              {{ scope.row.payType == 2 ? '微信' : '' }}
              {{ scope.row.payType == 1 ? '支付宝' : '' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态" width="120">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="全部订单" name="全部订单">
        <el-table border :header-cell-style="getRowClass" :data="tableData" style="width: 100%">
          <el-table-column align="center" prop="orderSn" label="订单编号" width="280">
          </el-table-column>
          <el-table-column align="center" prop="declareLevel" label="申报等级" width="100">
          </el-table-column>
          <el-table-column align="center" prop="certificateName" label="证书名称" width="160">
          </el-table-column>
          <el-table-column align="center" prop="certificateYear" label="年份" width="80">
          </el-table-column>
          <el-table-column align="center" prop="payAmount" label="价格(元)" width="80">
          </el-table-column>
          <el-table-column align="center" label="支付方式" width="80">
            <template slot-scope="scope">
              {{ scope.row.payType == 2 ? '微信' : '' }}
              {{ scope.row.payType == 1 ? '支付宝' : '' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态" width="120">
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <!--分页组件-->
    <el-pagination background @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="page.pageIndex" :page-size="page.pageSize" :total="page.totalPage"
      layout="total, prev, pager, next, jumper" style="text-align: center;">
    </el-pagination>
    <my-dialog title="提示" :visible.sync="deleteFlag" @before-close="refreshLX" width="363px">
      <div class="message">
        删除之后，相关学时也将对应减少，是否确认删除？
      </div>

      <el-row>
        <el-col class="flex_end_j">
          <div class="flex_cancel" @click="deleteFlag = false">取消
          </div>
          <div class="flex_confirm" @click="removeItem">确定
          </div>
        </el-col>
      </el-row>
    </my-dialog>
  </div>
</template>

<script>
import { httpGet, httpPost, httpNPPost } from "@/utils/httpRequest";

export default {
  name: "MineOrder",
  data() {
    return {
      // 加载
      tableDataLoading: true,
      activeName: '已下单',
      myOrder: {
        records: [],
      },
      deleteFlag: false,
      tableData: [

      ],
      BillData: [

      ],
      //分页对象
      page: {
        //当前页
        pageIndex: 1,
        //每页显示数据
        pageSize: 10,
        //总页数
        totalPage: 0,
      },
      deleteMsg: "",
    }
  },
  methods: {
    // 获取全部订单数据
    getMineOrderMsg() {
      this.tableDataLoading = true;
      httpGet("/app/app-order/list",
        {
          userId: localStorage.getItem('userId'),
          size: this.page.pageSize,
          current: this.page.pageIndex,
        },
        (res) => {
          // 分页总页数赋值
          this.page.totalPage = res.data.total;
          // console.log(res.data)
          this.BillData = []
          res.data.records.forEach((index) => {
            if (index.status == 1) {
              index.status = "待付款"
            } else if (index.status == 2) {
              index.status = "已付款"
            } else if (index.status == 3) {
              index.status = "交易完成"
            } else if (index.status == 4) {
              index.status = "退款中"
            } else if (index.status == 5) {
              index.status = "已退款"
            } else if (index.status == 6) {
              index.status = "已关闭"
            } else if (index.status == 7) {
              index.status = "无效订单"
            } else if (index.status == 0) {
              index.status = "已下单"
            }
            if (index.status == this.activeName) {
              this.BillData.unshift(index)
            }
            // console.log(index)
          })

          this.tableData = res.data.records
          this.tableData.forEach(index => {
            index.certificateYear = index.certificateYear.split('-')[0]
          })
          // 关闭加载
          this.tableDataLoading = false
        },
        (err) => {
          if (err.status == false) {
            localStorage.clear();
            if (!localStorage.getItem("userId")) {
              this.$message.error('登录过期，请重新登录!')
              this.$router.push("/login")
            }
          }
          // console.log(err)
          throw err
        })
    },
    // 获取等待付款数据
    getMineNoOrderMsg() {
      httpGet("/app/app-order/list",
        {
          userId: localStorage.getItem('userId'),
          size: this.page.pageSize,
          current: this.page.pageIndex,
          status: 0,
        },
        (res) => {
          // 分页总页数赋值
          this.page.totalPage = res.data.total;
          // console.log(res.data.records)
          res.data.records.forEach((index) => {
            if (index.status == 1) {
              index.status = "已付款"
            } else if (index.status == 2) {
              index.status = "已关闭"
            } else if (index.status == 3) {
              index.status = "无效订单"
            } else if (index.status == 4) {
              index.status = "交易完成"
            } else if (index.status == 0) {
              index.status = "待付款"
            }
          })
          this.BillData = res.data.records
          this.BillData.forEach(index => {
            // console.log(index)
            index.certificateYear = index.certificateYear.split('-')[0]
          })
        },
        (err) => {
          if (err.status == false) {
            localStorage.clear();
            if (!localStorage.getItem("userId")) {
              this.$message.error('登录过期，请重新登录!')
              this.$router.push("/login")
            }
          }
          // console.log(err)
          throw err
        })
    },
    // 立即付款按钮
    // confirmPayment(data) {
    //   console.log(data)
    //   // 微信支付请求
    //   if (data.payType == 0) {
    //     httpGet("/pay/wx/pc",
    //       {
    //         orderSn: data.orderSn,
    //       }, (res) => {
    //         console.log(res)
    //         this.$router.push({
    //           name: "Orderpayment",
    //           params: {
    //             // 支付方式 0 微信 1 支付宝 2网银支付
    //             checkPay: data.payType,
    //             imgSrc: res.data.qrCode,
    //             totalAmount: data.payAmount,
    //             orderSn: data.orderSn,
    //             certificateMsg: {
    //               name: data.certificateName,
    //               declareLevel: data.declareLevel,
    //               year: data.certificateYear,
    //             }
    //           }
    //         })
    //       }, (err) => {
    //         if (err.msg == "订单已关闭") {
    //           this.$message.error('订单已关闭，请重新申请证书')
    //           if (this.activeName == "first") {
    //             this.getMineOrderMsg();
    //           } else {
    //             this.getMineNoOrderMsg();
    //           }
    //         }
    //         // console.log(err)
    //       })
    //   }
    //   // 支付宝支付流程
    //   else if (data.payType == 1) {
    //     httpGet("/pay/ali/pc",
    //       {
    //         orderSn: data.orderSn,
    //       }, (res) => {
    //         console.log(res)
    //         this.$router.push({
    //           name: "Orderpayment",
    //           params: {
    //             // 支付方式 0 微信 1 支付宝 2网银支付
    //             checkPay: data.payType,
    //             totalAmount: data.payAmount,
    //             orderSn: data.orderSn,
    //             certificateMsg: {
    //               name: data.certificateName,
    //               declareLevel: data.declareLevel,
    //               year: data.certificateYear
    //             },
    //             imgSrc: res.data.qrCode,
    //           }
    //         })
    //       }, (err) => {
    //         if (err.msg == "订单已关闭") {
    //           this.$message.error('订单已关闭，请重新申请证书')
    //           if (this.activeName == "first") {
    //             this.getMineOrderMsg();
    //           } else {
    //             this.getMineNoOrderMsg();
    //           }
    //         }
    //         // console.log(err)
    //       })
    //   }
    //   // 银联支付流程
    //   else if (data.payType == 2) {
    //     httpGet("/pay/union/pc",
    //       {
    //         orderSn: data.orderSn,
    //       }, (res) => {
    //         console.log(res)
    //         this.$router.push({
    //           name: "Orderpayment",
    //           params: {
    //             // 支付方式 0 微信 1 支付宝 2网银支付
    //             checkPay: data.payType,
    //             imgSrc: res.data.qrCode,
    //             totalAmount: data.payAmount,
    //             orderSn: data.orderSn,
    //             certificateMsg: {
    //               name: data.certificateName,
    //               declareLevel: data.declareLevel,
    //               year: data.certificateYear
    //             }
    //           }
    //         })
    //       }, (err) => {
    //         if (err.msg == "订单已关闭") {
    //           this.$message.error('订单已关闭，请重新申请证书')
    //           if (this.activeName == "first") {
    //             this.getMineOrderMsg();
    //           } else {
    //             this.getMineNoOrderMsg();
    //           }
    //         }
    //         // console.log(err)
    //       })
    //   }
    // },
    // 选择支付方式
    placeOrder(data) {
      // console.log(data)
      let orderData = {
        orderSn: data.orderSn,
        totalAmount: data.totalAmount,
      }
      let certificateMsg = {
        declareLevel: data.declareLevel,
        name: data.certificateName,
        year: data.certificateYear,
        id: data.id,
        payType: data.payType
      }
      orderData = JSON.stringify(orderData)
      certificateMsg = JSON.stringify(certificateMsg)
      this.$router.push({
        name: 'Confirmorder',
        query: {
          data: orderData,
          certificateMsg: certificateMsg
        }
      })
    },
    // 立即支付
    confirmPayment(data) {
      // console.log(data)
      // console.log(this.$route.params)
      // 微信支付请求
      if (data.payType == 2) {
        httpGet("/pay/wx/pc",
          {
            orderSn: data.orderSn,
          }, (res) => {
            if (res.data.type == 1) {
              this.$router.push(
                {
                  name: "Payok",
                  params: {
                    orderSn: data.orderSn,
                    checkPay: data.payType,
                    certificateMsg: data.certificateMsg,
                    totalAmount: data.totalAmount,
                  }
                }
              )
            } else {
              // console.log(res)
              this.$router.push({
                name: "Orderpayment",
                params: {
                  // 支付方式 0 微信 1 支付宝 2网银支付
                  checkPay: data.payType,
                  imgSrc: res.data.qrCode,
                  totalAmount: data.totalAmount,
                  orderSn: data.orderSn,
                  certificateMsg: data.certificateMsg,
                }
              })
            }

          }, (err) => {
            // console.log(err)
          })
      }
      // 支付宝支付流程
      else if (data.payType == 1) {
        httpGet("/pay/ali/pc",
          {
            orderSn: this.orderSn,
          }, (res) => {
            // console.log(res)
            this.$router.push({
              name: "Orderpayment",
              params: {
                // 支付方式 0 微信 1 支付宝 2网银支付
                checkPay: data.payType,
                imgSrc: res.data.qrCode,
                totalAmount: data.totalAmount,
                orderSn: data.orderSn,
                certificateMsg: data.certificateMsg,
              }
            })
          }, (err) => {
            // console.log(err)
          })
      }
      // 银联支付流程
      // else if (data.payType == 2) {
      //   httpGet("/pay/union/pc",
      //     {
      //       orderSn: this.orderSn,
      //     }, (res) => {
      //       console.log(res)
      //       this.$router.push({
      //         name: "Orderpayment",
      //         params: {
      //           // 支付方式 0 微信 1 支付宝 2网银支付
      //           checkPay: this.checkPay,
      //           imgSrc: res.data.qrCode,
      //           totalAmount: this.totalAmount,
      //           orderSn: this.orderSn,
      //           certificateMsg: this.certificateMsg,
      //         }
      //       })
      //     }, (err) => {
      //       // console.log(err)
      //     })
      // }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },

    // 当前页
    currentChangeHandle(val) {
      this.page.pageIndex = val;
      console.log(111)
      this.getMineOrderMsg()
      // if (this.activeName == "first") {
      //   this.getMineOrderMsg();
      // } else {
      //   this.getMineNoOrderMsg();
      // }
    },
    // 确定
    removeItem() {
      httpNPPost("/app/app-order/delete?orderId=" + this.deleteMsg,
        (res) => {
          // console.log(res)
          this.deleteFlag = false;
          this.$message.success('删除成功!')
          if (this.activeName == "first") {
            this.getMineOrderMsg();
          } else {
            this.getMineNoOrderMsg();
          }
        },
        (err) => {
          if (err.status == false) {
            localStorage.clear();
            if (!localStorage.getItem("userId")) {
              this.$message.error('登录过期，请重新登录!')
              this.$router.push("/login")
            }
          }
          // console.log(err)
          throw err
        }
      )
    },
    refreshLX() {
      this.deleteFlag = false;
    },
    // 删除当前订单
    deleteItem(item) {
      this.deleteMsg = item.orderId;
      this.deleteFlag = true;
    },
    // 申请开票
    clickApply() {
      this.$router.push({ name: 'BillHandle', params: { id: 1 } });
    },
    // 详情页
    clickDetails(row) {
      this.$router.push({ name: 'Bill', params: { id: row.id } });
    },
    // 撤回申请
    clickWithdraw() {
      this.$router.push({ name: 'Bill', params: { id: 4 } });
    },
    // tab切换
    handleClick() {
      this.getMineOrderMsg()
      // if(this.activeName!="全部订单"){
      //   console.log(this.activeName)
      //   console.log(this.tableData)
      // }
    },
    // 表格数据多选
    handleSelectionChange() {

    },
    //表头颜色
    getRowClass() {
      return "background:#EBECF0;color:#3E454D;height: '2.125rem'";
    },
    //表体样式
    getCellStyle() {
      return "font-size: 0.875rem;\n" +
        "font-family: PingFang SC-Medium, PingFang SC;\n" +
        "font-weight: 500;\n" +
        "color: #3E454D;";
    },
  },
  mounted() {
    this.getMineOrderMsg()
  }
}
</script>

<style lang="scss" scoped>
.mine-order {
  padding: 0 32px;
  background: #FFFFFF;
  border-radius: 10px;
  height: 49.5rem;

  .message {
    font-size: 1.125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5rem;
    text-align: left;
    padding: 0.75rem 1.5rem 3rem 2rem;
  }

  .message::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url('~@/assets/img/pre/icon-tip-1.png');
    background-size: 100% 100%;
    margin-right: 0.25rem;
  }

  .flex_end_j {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    height: 3.25rem;
    border-top: 0.0625rem solid #EDEFF2;
    align-items: center;

    .flex_cancel {
      width: 15.97796%;
      height: 2rem;
      background: #FFFFFF;
      border-radius: 10px;
      opacity: 1;
      border: 0.0625rem solid #E6E8EB;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3E454D;
      line-height: 2rem;
      margin-right: 0.625rem;
      cursor: pointer;
    }

    .flex_confirm {
      width: 15.97796%;
      height: 2rem;
      background: #1371E7;
      box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
      border-radius: 10px;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 2rem;
      cursor: pointer;
    }
  }

  .mine-order-head {
    background: #EBECF0;
    height: 2.125rem;
    line-height: 1.875rem;
    font-size: 0.75rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E454D;

    th:nth-child(n + 2) {
      width: 9rem;
      text-align: center;
    }
  }

  .orders {
    margin-top: 1.25rem;
    // line-height: 2.75rem;
    // height: 2.75rem;

    .mine-order-item {
      font-size: 0.75rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #3E454D;
      width: 100%;
      margin-top: 1.25rem;

      .btn {
        display: inline-block;
        width: 5.25rem;
        height: 2rem;
        background: #FFFFFF;
        border-radius: 10px;
        opacity: 1;
        border: 0.0625rem solid #1371E7;
        font-size: 0.8125rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1371E7;
        line-height: 2rem;
        cursor: pointer;
      }

      .btn:hover {
        background: #1371E7;
        box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
        border-radius: 10px;
        color: #FFFFFF;
      }

      .typographic {
        display: flex;
        align-items: center;

        .typographic-div {
          height: 2.875rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 0.625rem;

          .title {
            height: 1.125rem;
            font-size: 0.875rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #3E454D;
            line-height: 1.125rem;
          }

          .year {
            height: 1.125rem;
            font-size: 0.875rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #667280;
            line-height: 1.125rem;
          }
        }

        span {
          display: block;
        }
      }

      .order-title {
        padding-left: 0.75rem;
      }

      th {

        background: #EDEFF2;

      }

      table {
        border-collapse: collapse;
      }

      td {
        height: 5rem;
      }

      table,
      th,
      td {
        border: 0.0625rem solid #EBEEF5;
      }

      td:nth-child(n + 2) {
        width: 9rem;
        text-align: center;
      }

      .delete {
        font-size: 0.75rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
        padding-right: 0.625rem;
      }

      .delete::before {
        content: "";
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: url('~@/assets/img/pre/icon-delete.png');
        background-size: 100% 100%;
        margin-right: 0.625rem;
      }
    }
  }

  table {
    border-collapse: collapse;
  }

  .apply {
    width: 5.25rem;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    text-align: center;
    margin-top: 1.25rem;
  }

  // 复选康
  ::v-deep .el-checkbox__inner {
    width: 1rem;
    height: 1rem;
  }

  // tabs
  ::v-deep .el-tabs {
    background-color: #FFFFFF;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #1371E7;
    height: 0.25rem;
  }

  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #000000;
  }

  ::v-deep .el-tabs__item {
    height: 4rem;
    line-height: 4rem;
    font-size: 0.9375rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0 2.5rem;
    color: #667280;
  }

  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  ::v-deep .el-tabs__content {
    height: 40.0625rem;
    margin-top: 1.25rem;
    overflow: hidden;
    overflow-y: scroll;
    text-align: left;
  }
}
</style>
